import type React from 'react';

function withBasicProvider<P>(
  Provider: React.ComponentType<{ children: React.ReactNode }>,
  Component: React.ComponentType<P>
) {
  return function WithBasicProvider(props: P) {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    );
  };
}

export { withBasicProvider };
