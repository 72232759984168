import { useDebugStore } from '@/hooks/use-debug-store';
import dynamic from 'next/dynamic';
import React from 'react';
import {
  forceUpdateKey,
  longCopy,
  longCopyThreshold,
  shortCopy,
} from './config';

export const StaggeredTextContext = React.createContext({
  longCopyThreshold,
  shortControls: shortCopy,
  longerControls: longCopy,
  forceUpdateKey,
});

interface ChildrenProps {
  children: React.ReactNode;
}

const LevaProvider = dynamic<ChildrenProps>(
  () => import('./with-leva').then((mod) => mod.LevaProvider),
  { ssr: false }
);

const DefaultProvider = dynamic<ChildrenProps>(
  () => import('./without-leva').then((mod) => mod.DefaultProvider),
  { ssr: false }
);

export function StaggeredTextProvider({ children }: ChildrenProps) {
  const { isLevaVisible } = useDebugStore();

  const Provider = isLevaVisible ? LevaProvider : DefaultProvider;

  return <Provider>{children}</Provider>;
}
