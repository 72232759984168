export const longCopyThreshold = 10;
export const forceUpdateKey = 0;

export const longCopy = {
  duration: 0.55,
  delay: 0.03,
  colorStart: 1,
  curve: [0.4, 0, 0.2, 1],
};

export const shortCopy = {
  duration: 0.8,
  delay: 0.1,
  colorStart: 0.22,
  curve: [0.46, 0.03, 0.52, 0.96],
};
